import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const cardTitle = 'text-2xl md:text-4xl text-ff-blue-10 uppercase font-bold text-white text-center mb-2'
const cardDesc = 'text-base md:text-xl text-center'

const PageServicesSectionNew = () => {
  return (
    <div className="bg-white">
      <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12">
            <p className="text-lg md:text-xl text-ff-blue-10 font-medium leading-relaxed uppercase mt-4 mb-4">
              Towels, linens, Uniforms, and More
            </p>

            <h2 className="text-5xl md:text-6xl font-bold leading-tight">
              Areas of Expertise
            </h2>

            <p className="text-xl md:text-2xl font-medium leading-relaxed mt-4 mb-4">
              Rest assured, your laundry is in expert hands. We specialize in thoroughly cleaning and sanitizing a wide range of items – from towels and linens to uniforms and beyond. Our state-of-the-art equipment, combined with our meticulous team, guarantees the highest standards of cleanliness and care.
            </p>
          </div>

          <div className='w-full md:w-10/12 mx-auto mt-16'>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4'>
              <StaticImage
                src="../../images/items/towels.jpg"
                width={640}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Healthcare business"
              />
              <div className='flex flex-col items-center justify-center'>
                <h2 className={cardTitle}>Towels</h2>
                <p className={cardDesc}>Fresh, soft, and hygienic towels - guaranteed. Our expert cleaning process utilizes high-quality detergents and commercial-grade equipment to remove dirt, bacteria, and odors, providing you with the highest standards of cleanliness.</p>
              </div>
            </div>

            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-16'>              
              <div className='order-2 md:order-1 flex flex-col items-center justify-center'>
                <h2 className={cardTitle}>Linens</h2>
                <p className={cardDesc}>From sorting to sanitizing, our team's expertise shines through. We meticulously sort and clean your linens, ensuring they are spotless and ready for immediate use. We exclusively use gentle, environmentally conscious cleaning agents to preserve the beauty and integrity of your fabrics.</p>
              </div>
              <StaticImage
                src="../../images/items/linens-1.jpg"
                width={640}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Restaurants business"
                className='order-1 md:order-2'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageServicesSectionNew